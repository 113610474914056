import styled from "styled-components";
import { FloatingLabel, Form } from 'react-bootstrap';

export const StyledFloatingLabel = styled(FloatingLabel)`
  & input.form-control, 
  & select.form-control {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px #ccc;
    border-radius: 0px !important;
    background-color: transparent !important;
    z-index: 1;
  }
`;

export const StyledRSVPForm = styled(Form)`
  background-color: var(--text-white);
  padding: 10px 20px 20px 20px;
  border-radius: 15px;
  box-shadow: 6px 6px 18px 0px var(--box-shadow-color);
`;

export const StyledNumberInput = styled(Form.Control)`
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px #ccc;
  border-radius: 0px !important;
  background-color: transparent !important;
`;

export const StyledTextArea100 = styled(Form.Control)`
  height: 100px;
  width: 100%;
  border: solid 1px #ccc;
  padding-left: 10px;
  padding-right: 5px;
`;