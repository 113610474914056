import React from 'react';
import { Link, useNavigate, /* useLocation */ } from 'react-router-dom';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
// import { useUserData } from "../contexts/AuthContext";
// import IconButton from './common/IconButton';
// import { humanize } from "../utils/";

const APP_NAME = process.env.REACT_APP_NAME || "esign-sandbox-client";
const HeaderMenu = [
  // { page_title: "Reports", page_link: "/reports", disabled: false, adminOnly: false },
];

const MainHeader = ({ toggleTheme, theme, ...props } = {}) => {
  const navigate = useNavigate();
  // let { pathname = "/login" } = useLocation();
  // const { userData, handleLogout } = useUserData();

  // let { first_name = '', middle_name = '', last_name = '', role_id = 5, profile_picture = '', isAuthenticated = false } = userData || {};
  // let user_info = `${first_name ? first_name : ""}${middle_name ? " " + middle_name : ""}${last_name ? " " + last_name : ""}`.trim();
  // let admin_access = [1, 2].includes(role_id);
  // let has_access = isAuthenticated && role_id < 5;

  // let page = HeaderMenu.find(({ page_link = "#", page_title = '', adminOnly = false, groupOnly = false, disabled = false } = {}) => {
  //   return (page_title && !disabled &&
  //     (adminOnly && admin_access) &&
  //     (!groupOnly || groupOnly === role_id)) &&
  //     (Array.isArray(page_link) ? page_link.some((pg) =>
  //       pathname === pg
  //       || (pg.includes(":") && pathname.includes(pg.substring(0, pg.indexOf(':'))))
  //     ) : pathname === page_link)
  // })?.page_title;

  return (<header {...props}>
    <Navbar collapseOnSelect expand="lg" variant="light" className="header-main-nav">
      <Navbar.Brand onClick={() => navigate("/")}></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          {/* {has_access && HeaderMenu.filter(({ adminOnly = false, groupOnly = false, disabled = false, page_title = '' }) => {
            return page_title && !disabled && ((adminOnly && admin_access) || !adminOnly) && (!groupOnly || groupOnly === role_id);
          }).map(({ page_title, page_link }, index) => {
            (Array.isArray(page_link)) && (page_link = page_link[0]);
            return (<span key={`header-${page_title}-${index}`} className="header-nav-link">
              <Link to={page_link} className={`nav-link ${(pathname === page_link || page === page_title) ? "active" : ""}`}>{page_title}</Link>
            </span>)
          })} */}
        </Nav>
        <Nav>
          <Nav.Item as="li">
            {/* <IconButton variant={theme} size="lg" icon={theme} className="ms-2" iconAlign="left" onClick={toggleTheme} /> */}
          </Nav.Item>
          <Dropdown navbar={true} as="li">
            <Dropdown.Toggle as={Link} to="#!" bsPrefix="toggle" className="nav-link pe-0 ps-2" >
              <div>
                {/* <img style={{ width: "40px", borderRadius: "50%" }} src={profile_picture} alt={user_info} /> */}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
              {/* {has_access && <Dropdown.Item as={Link} to="/user/profile">My Profile</Dropdown.Item>} */}
              <Dropdown.Divider />
              {/* <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>)
};

export default MainHeader;