import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
// import { useUserData } from "./contexts/AuthContext";
import useTheme from "./hooks/UseTheme";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from "./styled/Global";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./styled/Themes";

import MainHeader from "./components/MainHeader";
import Footer from "./components/Footer";
import QrLanding from "./pages/QrLanding";

// const DEFAULT_PAGE = process.env.REACT_APP_DEFAULT_PAGE || '/unauthorized';

const MainLayout = () => {
  const [theme, toggleTheme] = useTheme();
  const currentTheme = theme === "light" ? lightTheme : darkTheme;
  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <div className="App bg-dark">
        {/* <MainHeader toggleTheme={toggleTheme} theme={theme} /> */}
        <Outlet />
        <Footer theme={theme} />
      </div>
    </ThemeProvider>);
}

const App = () => {
  return (<Router basename={process.env.PUBLIC_URL}>
    <Routes>
      <Route element={<MainLayout />} >
        <Route path="/" element={<QrLanding />} />
      </Route>
      {/* <Route path="/unauthorized" element={<Unauthorized theme={theme} />} /> */}
      {/* <Route path="*" element={<Navigate to={DEFAULT_PAGE} replace />} /> */}
    </Routes>
  </Router>);
};

export default App;
