import axios from "axios";
// import { getParams } from ".";
import { getClientDigitalInformation } from "./ipify";
const IP_CACHE_ENABLED = process.env.REACT_APP_IP_CACHE_ENABLED === "true";
const AXIOS_TIMEOUT_MS = 1000 * Number(process.env.REACT_APP_AXIOS_TIMEOUT_SECONDS || 300); // In Milliseconds (default 5minutes)
let AXIOS_OPTIONS = { timeout: AXIOS_TIMEOUT_MS }; // (in ms) timeout of zero means there is no timeout limit 

const Guest = {
  session: async () => {
    let { ip } = IP_CACHE_ENABLED ? await getClientDigitalInformation() : {};
    return await axios.get(`/session${ip ? `?ip=${ip}` : ''}`, AXIOS_OPTIONS);
  },
  login: async (payload = {}) => {
    let { ip } = IP_CACHE_ENABLED ? await getClientDigitalInformation() : {};
    if (ip) {
      payload.ip = ip;
    }
    return await axios.post(`/auth`, payload, AXIOS_OPTIONS);
  },
  logout: () => axios.get(`/logout`, AXIOS_OPTIONS),
  // updateUser: ({ user_id, ...payload } = {}) => axios.patch(`/users/${user_id}`, payload, AXIOS_OPTIONS),
  // getUser: ({ user_id } = {}) => axios.get(`/users/${user_id}`, AXIOS_OPTIONS),
};

const API = {
  Guest,
};

export {
  Guest,
};
export default API;

/* NOTE: getAllCharges can be accessed by any of the following methods
import { getAllCharges } from "./utils/api"; // Because Of the 'export' above

import API from "./utils/api"; // Because of the 'default export'
const { getAllCharges } = API;

import API from "./utils/api";
API.getAllCharges
*/