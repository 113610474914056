import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Form, FloatingLabel, Image } from 'react-bootstrap';
import IconButton from "../components/common/IconButton";
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { DisplayFormikState } from '../components/formik-demo/formik-demo';
import { StyledFloatingLabel, StyledRSVPForm, StyledNumberInput, StyledTextArea100 } from '../styled/Forms';
import { capitalize } from "../helpers/utils";
import { ToastContainer, toast } from 'react-toastify';
import SimpleImageSlider from "react-simple-image-slider";
import { Guest } from "../utils/API";
import { shuffle_array } from "../utils/";
import homeSliderImages from "../slider-data/home-slider.json"
import portraitSliderImages from "../slider-data/portrait-slider.json"
const SHOW_DEBUG = false;

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Your name has to be longer than that")
      .required('First name is required.'),
    lastName: Yup.string()
      .min(3, "Your name has to be longer than that")
      .required('Last name is required.'),
    email: Yup.string()
      .email('Invalid email address'),
    // additional_guests: Yup.number(),
    numberOfAdults: Yup.number(),
    numberOfChildren: Yup.number(),
    comments: Yup.string(),
  }),

  mapPropsToValues: ({ invitee }) => ({
    ...invitee,
  }),
  handleSubmit: async (payload, { setSubmitting }) => {
    let resp;
    try {
      resp = await Guest.login(payload);
    } catch (error) {
      console.error('Guest.login Error:', error);
      resp = error?.response;
    }

    let { status, statusText, data = {} } = resp || {};
    let { message = '', first_name, last_name } = data || {};
    if (typeof data === 'string') {
      message = data;
    } else if (data?.status && !data?.message) {
      message = data.status;
    }
    message = `${statusText} (${status}) ${message} ${first_name} ${last_name}`;
    console.log(message);

    let successMessage = `Thanks ${first_name ? first_name + " " : ""} ${last_name ? last_name + " " : ""}we look forward to seeing you there!`;
    toast.success(successMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setSubmitting(false);
    return;
  },
  displayName: 'MyForm',
});

const InputFeedback = ({ error }) =>
  error ? <div className="input-feedback mx-1">{error}</div> : null;

const FloatingTextInput = ({ type = "text", id, label, error, value, onChange, className, ...props }) => {
  return (
    <StyledFloatingLabel controlId={id} label={label} size="sm" className={className}>
      <Form.Control
        size="sm"
        type={type}
        name={id}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </StyledFloatingLabel>
  );
};

const FloatingTextArea = ({ type = "text", id, label, error, value, onChange, className, ...props }) => {
  return (<FloatingLabel controlId={id} label={label} className={className}>
    <StyledTextArea100
      as="textarea"
      name={id}
      value={value}
      onChange={onChange}
      {...props}
    />
  </FloatingLabel>);
};

const MyForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    isSubmitting,
  } = props;

  const onChangeTextWrapper = (e) => {
    if (!e?.target) {
      return;
    }
    const { value } = e.target || {};
    e.target.value = `${value}`.split(" ").map((word) => capitalize(word)).join(" ");
    return handleChange(e);
  };

  return (<StyledRSVPForm onSubmit={handleSubmit} onReset={handleReset} className="d-flex flex-column justify-content-center w-100">
    <Row>
      <Col>
        <h3 className="pretty-print2 text-center">Please RSVP</h3>
      </Col>
    </Row>
    <Row>
      <Col>
        <FloatingTextInput
          id="firstName"
          type="text"
          label="First Name"
          placeholder=""
          error={touched.firstName && errors.firstName}
          value={values.firstName}
          onChange={onChangeTextWrapper}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Col>
      <Col>
        <FloatingTextInput
          id="lastName"
          type="text"
          label="Last Name"
          placeholder=""
          error={touched.lastName && errors.lastName}
          value={values.lastName}
          onChange={onChangeTextWrapper}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Col>
    </Row>
    <Row>
      <Col lg="12" md="12">
        <FloatingTextInput
          id="email"
          type="email"
          label="Email (not required)"
          placeholder="Enter your email (not required)"
          error={touched.email && errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Col>
    </Row>
    <Row>
      <Form.Label htmlFor="numberOfAdults" className="mt-3 pt-3 col-4">Number Of Adults?</Form.Label>
      <Col lg="2" md="2" className="mt-4">
        <StyledNumberInput
          id="numberOfAdults"
          name="numberOfAdults"
          type="number"
          min={0}
          max={99}
          placeholder=""
          onChange={handleChange}
          value={values.numberOfAdults}
          onBlur={handleBlur}
          autoComplete="off"
          required
        />
      </Col>
      <Form.Label htmlFor="numberOfChildren" className="mt-3 pt-3 col-4">Number Of Children?</Form.Label>
      <Col lg="2" md="2" className="mt-4">
        <StyledNumberInput
          id="numberOfChildren"
          name="numberOfChildren"
          type="number"
          min={0}
          max={99}
          placeholder=""
          onChange={handleChange}
          value={values.numberOfChildren}
          onBlur={handleBlur}
          autoComplete="off"
          required
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FloatingTextArea
          className="mt-2"
          id="comments"
          label=""
          placeholder="Want to add a comment? (not required)"
          error={touched.comments && errors.comments}
          value={values.comments}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Col>
    </Row>
    <Form.Group className="d-flex w-100 mt-2">
      <IconButton variant="secondary" size="lg" icon="check" className="flex-fill me-2" iconAlign="left" type="reset" disabled={!dirty || isSubmitting}>
        <span className="ms-1">Clear</span>
      </IconButton>
      <IconButton variant="primary" size="lg" icon="check" className="flex-fill" iconAlign="left" type="submit" disabled={isSubmitting}>
        <span className="ms-1">{isSubmitting ? "Saving..." : "Submit"}</span>
      </IconButton>

    </Form.Group>
    {SHOW_DEBUG && <DisplayFormikState {...props} className="border d-flex flex-column justify-content-center w-100" />}
  </StyledRSVPForm>
  );
};

const MyEnhancedForm = formikEnhancer(MyForm);

const QrLanding = ({ theme, children, ...props } = {}) => {
  const pageContainerRef = useRef(null);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (<Row className="bg-light d-flex page-container" ref={pageContainerRef}>
    <ToastContainer />
    <Col lg="12" md="12" sm="12" className="d-flex justify-content-center align-items-center mb-4 pe-0">
      <div className="d-flex flex-column justify-content-center align-items-center box-shadow">
        <h1 className="pretty-print">Christopher + Chrystal</h1>
        <h5 className="pretty-print2">INVITE YOU TO CELEBRATE THEIR WEDDING</h5>
        <SimpleImageSlider
          id="home-slider"
          height={dimensions?.width * 0.66 || 270}
          width={dimensions?.width || 405}
          images={shuffle_array(homeSliderImages)}
          showNavs={true}
          loop={true}
          autoPlay={true}
          autoPlayDelay={3}
        />
      </div>
    </Col>
    {/* <Col lg="12" md="12" sm="12" className="d-flex justify-content-center align-items-center my-4 pe-0">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <MyEnhancedForm invitee={{ firstName: '', lastName: '', email: '', numberOfAdults: 0, numberOfChildren: 0, comments: '' }} />
      </div>
    </Col> */}
    <Col className="my-4">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h3 className="pretty-print2">🎁 Gifts</h3>
        <p className="big-text">We know it isn't traditional, it's not the way it's done...
          <br />
          but instead of gifts or presents, we'd like a little fun!
          <br />
          So if you'd like to give a gift to help us celebrate...
          <br />
          some help towards our honeymoon, we would appreciate!
          <br />
          The choice is really up to you and we would like to say...
          <br />
          that the best gift we could receive is you here on our special day!
          <br />
          Thank you!
        </p>
        <Row>
          <Col lg="6" md="6" sm="12" className="text-center" >
            <Link to="https://venmo.com/u/Christopher-Collins-44" className="btn btn-light pb-2 box-shadow gift-links">
              <Image src="/images/venmo-christopher-collins-44.png" alt="venmo me @christopher-collins-44" width="250" />
            </Link>
          </Col>
        </Row>
      </div>
    </Col>
    <Col lg="12" md="12" sm="12" className="d-flex justify-content-center align-items-center mt-4">
      <h1 className="pretty-print">💕My love is all for you</h1>
    </Col>
    <Col lg="12" md="12" sm="12" className="d-flex justify-content-center align-items-center mb-4">
      <div className="d-flex flex-column justify-content-center align-items-center box-shadow" id="portrait-slider">
        <SimpleImageSlider
          height={(dimensions?.width * 0.75) * 1.5 || 405}
          width={dimensions?.width * 0.75 || 270}
          images={shuffle_array(portraitSliderImages)}
          showNavs={true}
          loop={true}
          autoPlay={true}
          autoPlayDelay={2}
        />
      </div>
    </Col>
  </Row>);
};

export default QrLanding; 