import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPlus, faRotate, faMoon, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faSun } from "@fortawesome/free-regular-svg-icons";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
const iconAlias = {
  'clear': faBan,
  'plus': faPlus,
  'sync': faRotate,
  'dark': faMoon,
  'light': faSun,
  'check': faCheck,
  'x': faXmark,
  'google': faGoogle,
  'facebook': faFacebook,
};

const IconButton = ({
  icon,
  iconAlign = 'left',
  iconClassName,
  transform,
  children,
  ...rest
}) => (
  <Button {...rest}>
    {iconAlign === 'right' && children}
    <FontAwesomeIcon
      icon={icon in iconAlias ? iconAlias[icon] : icon}
      className={classNames(iconClassName, {
        'me-1': children && iconAlign === 'left',
        'ms-1': children && iconAlign === 'right'
      })}
      transform={transform}
    />
    {iconAlign === 'left' && children}
  </Button>
);

export default IconButton;
