import React from "react";
// import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
// import { humanize } from "../utils/";
// import { useUserData } from "../contexts/AuthContext";
// const APP_NAME = process.env.REACT_APP_NAME || "";

const Footer = ({ theme, ...props } = {}) => {
  // const { userData } = useUserData();
  // const { isAuthenticated = false, role_id = 5 } = userData || {};
  // let has_access = isAuthenticated && role_id < 5;

  return (
    <footer id="main-footer" {...props}>
      <div id="footer-bottom">
        <div id="footer-info">
          <Row>
            <Col className="d-flex justify-content-center">
              Copyright © {new Date().getFullYear()} wedding.ccollins.io
            </Col>
            {/* <Col className="d-flex justify-content-end">
              {has_access && <Link to={"/privacy-policy"}>Privacy Policy</Link>}
            </Col> */}
          </Row>
        </div>
      </div>
    </footer >
  );
}

export default Footer;
