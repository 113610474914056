import axios from "axios";

function get(url) {
  return axios.get(url).then(res => res.data).catch(err => { console.log(err); return null; });
}

const ipify = {
  ipv4() {
    return get('https://api.ipify.org');
  },
  ipv64() {
    return get('https://api64.ipify.org');
  },
  geolocation() {
    return get('https://geolocation-db.com/json/');
  },
};

/**
 * Uses ipify functions to get external ip 
 * @returns string IP 
 */
const getExternalIP = async () => {
  let external_ip = await ipify.ipv4();
  if (!external_ip) {
    external_ip = await ipify.ipv64();
  }
  if (!external_ip) {
    const { IPv4 } = await ipify.geolocation(); // returns { country_code, country_name, city, postal, latitude, longitude, IPv4, state }
    external_ip = IPv4;
  }
  return external_ip;
};

const getClientDigitalInformation = async () => {
  let { userAgent: user_agent } = navigator || window?.navigator || {};
  let { location } = window || {};
  let { hostname } = location || {};
  let response = {
    timestamp: new Date(),
    ip: await getExternalIP(),
    user_agent,
  };
  // Detect if running from localhost 
  if (["localhost", "127.0.0.1"].includes(hostname)) {
    console.log(`Running from ${hostname} at ip ${response.ip}`);
  }
  return response;
}

export {
  ipify,
  getExternalIP,
  getClientDigitalInformation,
};