import { createGlobalStyle } from "styled-components";

// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,900|Ubuntu');
export const GlobalStyle = createGlobalStyle`
:root {
  --body-text: ${({ theme: { bodyText = "#243D25" } = {} } = {}) => bodyText}; 
  --secondary-color: ${({ theme: { secondaryColor = "#5F7464" } = {} } = {}) => secondaryColor}; 
  --body-background: ${({ theme: { bodyBackground = "#FAD9E6" } = {} } = {}) => bodyBackground}; 
  --accent-text: ${({ theme: { accentText = "#E4AEC5" } = {} } = {}) => accentText}; 
  --text-light-pink: ${({ theme: { lightPink = "#FAD9E6" } = {} } = {}) => lightPink}; 
  --text-white: ${({ theme: { textWhite = "#ffffff" } = {} } = {}) => textWhite}; 
  --text-red: ${({ theme: { textRed = "#E61B1B" } = {} } = {}) => textRed};
  --text-dark-red: ${({ theme: { textDarkRed = "#ac1010" } = {} } = {}) => textDarkRed};
  --text-green: ${({ theme: { textGreen = "#2F9B2F" } = {} } = {}) => textGreen};
  --text-light-green: ${({ theme: { textLightGreen = "#E61B1B" } = {} } = {}) => textLightGreen};
  --box-shadow-color: ${({ theme: { boxShadowColor = "#E61B1B" } = {} } = {}) => boxShadowColor};
}

@font-face {
  font-family: 'Aniyah';
  src: local('Aniyah'), url(./fonts/Aniyah.otf) format('opentype');
}

@font-face {
  font-family: 'BakerieRough';
  src: local('BakerieRough-Regular'), url(./fonts/BakerieRough-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Agrandir';
  src: local('Agrandir-TextBold'), url(./fonts/Agrandir-TextBold.otf) format('opentype');
}

.pretty-print {
  font-family: "Aniyah";
  font-weight: 900;
  line-height: 3;
  color: var(--body-text);
}
.pretty-print2 {
  font-family: "BakerieRough";
}

.cash-app-text {
  font-family: "Agrandir";
}

.box-shadow {
  box-shadow: 6px 6px 18px 0px var(--box-shadow-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Ubuntu";
  color: var(--body-text);
  background-color: var(--body-background);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  fontSize: '.65rem',
  padding: '.5rem',
  background-color: var(--accent-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu";
  font-weight: 900;
  color: var(--body-text);
}

.big-text {
  font-family: "BakerieRough";
  font-size: 1.5rem;
  padding: 0px 20px 10px 20px;
}

.label {
  color: var(--body-text);
}

.navbar-brand {
  color: var(--body-text);
}
.navbar-brand:focus, .navbar-brand:hover {
  color: var(--body-background);
}

.navbar-dark, .navbar[data-bs-theme=dark] {
  background-color: var(--body-text);
}
.navbar-light, .navbar[data-bs-theme=light] {
  background-color: var(--body-background);
}

.bg-light {
  background-color: var(--body-background) !important;
}

.bg-white {
  background-color: var(--text-white) !important;
}

#main-footer {
  background-color: var(--text-white);
}

/* Form */
.input-feedback {
  color: var(--accent-text);
}
input.form-control {
  color: var(--body-text);
}

.form-floating>.form-control-plaintext~label::after, 
.form-floating>.form-control:focus~label::after, 
.form-floating>.form-control:not(:placeholder-shown)~label::after, 
.form-floating>.form-select~label::after {
  background-color: transparent;
}

/* Buttons */ 
.btn {
  font-family: "BakerieRough";
}
.btn-primary {
  color: var(--text-white);
  background-color: var(--accent-text);
  border-color: var(--accent-text);
}
.btn-primary:hover {
  color: var(--accent-text);
  background-color: var(--text-light-pink);
  border-color: var(--text-light-pink);
}

.btn-secondary {
  color: var(--text-white);
  background-color: var(--body-text);
  border-color: var(--body-text);
}
.btn-secondary:hover {
  color: var(--body-text);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-light {
  color: var(--body-text);
  background-color: var(--text-light-pink);
  border-color: var(--text-light-pink);
}
.btn-light:hover {
  color: var(--body-text);
  background-color: var(--accent-text);
  border-color: var(--accent-text);
}

#portrait-slider {
  border: solid 6px #333;
};

/* @media only screen and (max-width: 768px)
/*------------------------------------------------------*/
@media only screen and (max-width: 768px) {
  .gift-links {
    margin: 10px 20px 10px 20px;
  }
}

/* @media only screen and (max-width: 600px)
/*------------------------------------------------------*/
@media only screen and (max-width: 600px) {
  .pretty-print {
    font-size: 1.5rem;
  }
  .pretty-print2 {
    font-size: 1rem;
  }
  .big-text {
    font-size: 1.2rem;
  }
}

/* @media only screen and (max-width: 450px)
/*------------------------------------------------------*/
@media only screen and (max-width: 450px) {
  .pretty-print {
    font-size: 1.2rem;
  }
}
`;
