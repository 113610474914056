/**
 * Main Theme Colors
 * light pink: #FAD9E6
 * dark pink: #E4AEC5
 * light green: #5F7464 
 * dark green: #243D25
 */

const sharedStyles = {
  bodyText: "#243D25",        // dark green 
  secondaryColor: "#5F7464",  // light green
  bodyBackground: "#FFFFF3",  // off white
  accentText: "#E4AEC5",      // dark pink
  lightPink: "#FAD9E6",       // light pink
  lightGrey: "#ECECEC",
  darkGrey: "#d1d1d1",
  grayLine: "#c7c7c7",
  gray: "#CDCDCD",
  textWhite: "#ffffff",
  mutedText: "#b7b7b7",
  textRed: "#E61B1B",
  textDarkRed: "#ac1010",
  textGreen: "#2F9B2F",
  textLightGreen: "#5BAF55",
  boxShadowColor: "#5F7464",
};

export const lightTheme = {
  ...sharedStyles,
};

export const darkTheme = {
  ...sharedStyles,
};